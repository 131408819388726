import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PrimitiveJsonResponse } from "../../models/http/primitive-json-response.model";
import { LektoSystemEnum } from "../../enums/lekto-system.enum";
import { UiService } from "../essential/ui.service";
import { ImpersonateRequest } from "../../models/requests/auth/impersonate-request.model";
import { ServiceBase } from "../http/service-base";
import { ja_JP } from "ng-zorro-antd/i18n";
import { Apollo } from "apollo-angular";
import { LektoAuthService } from "../essential/lekto-auth.service";

@Injectable({
  providedIn: "root",
})
export class UserAuthService extends ServiceBase {
  private serviceUrl = `${environment.baseUrl}/api/auth`;

  getApiEndpoint(): string {
      return this.serviceUrl;
  }

  constructor(
    protected http: HttpClient,
    protected apollo: Apollo,
    private uiService: UiService,
    private authService: LektoAuthService
  ) {
    super(http, apollo);
  }

  impersonate(
    idUser: number,
    request: ImpersonateRequest
  ): Observable<PrimitiveJsonResponse<string>> {
    request.logoutUrl = window.location.origin;

    return this.http
      .post<
        PrimitiveJsonResponse<string>
      >(`${this.serviceUrl}/impersonate/${idUser}`, request)
      .pipe(take(1));
  }

  redirectImpersonatedTo(
    system: LektoSystemEnum,
    impersonatedToken: string,
    newTab: boolean = true
  ) {
    const url = this.getImpersonateRedirectUrl(system, impersonatedToken);
    this.uiService.executeRedirection(url, newTab);
  }

  getImpersonateRedirectUrl(
    system: LektoSystemEnum,
    impersonatedToken: string
  ): string {
    const url = `${environment.apiUrl}/auth/redirect-to/${LektoSystemEnum[system]}/token/${impersonatedToken}`;
    return url;
  }

  getZendeskRedirectionUrl(returnUrl: string){
    const token = this.authService.getTokenData()?.access_token;
    const url = `${environment.apiUrl}/auth/zendesk?accessToken=${token}&returnUrl=${returnUrl}`;
    return url;
  }

  redirectToZendesk(returnUrl: string){
    const url = this.getZendeskRedirectionUrl(returnUrl);
    this.uiService.openWindow(url);
  }
}
